import React from "react"
import { Link } from "gatsby"
import css from "@styled-system/css"
import { mdiChevronRight } from "@mdi/js"
import { useStaticQuery, graphql } from "gatsby"

import { useContact } from "contexts/contact"

import Flex from "../flex"
import Box from "../box"
import Container from "../container"
import Icon from "../icon"
import Text from "../text"
import { H1, H2, H6 } from "../headings"

import chevron from "./chevron.png"

const Resource = ({ label, onClick, href, internal }) => (
  <Box
    as={href ? "a" : "div"}
    onClick={onClick}
    href={href}
    rel={internal ? "" : "noopener noreferrer"}
    target={internal ? "" : "_blank"}
    css={css({
      cursor: "pointer",
      textDecoration: "none",
      "&:not(:last-child) > *": {
        borderBottom: "1px solid",
        borderColor: "text",
      },
    })}
  >
    <Flex alignItems="center" py={2}>
      <Box flex={1}>
        <H6 children={label} />
      </Box>
      <Box mx={2}>
        <Icon symbol={mdiChevronRight} color="text" size={1.5} />
      </Box>
    </Flex>
  </Box>
)

function Locator() {
  const { setContactOpen, setReason } = useContact()

  const { contact } = useStaticQuery(
    graphql`
      query {
        contact: datoCmsContact {
          resourcesCopy
          finishCatalogLink
          finishStudioLink
          trainingLink
          swagLink
        }
      }
    `
  )

  return (
    <Box as="footer" py={[5, 6]} bg="primary">
      <Container as="section">
        <Text
          children={"Find your friendly neighborhood Branch."}
          mb={3}
          fontFamily="condensed"
          fontSize={[5, 6]}
        />
      </Container>
      <Container as="section">
        <Box
          css={css({
            display: "grid",
            gridTemplateColumns: ["1fr", null, "1fr 64px 1fr"],
            gridTemplateRows: ["auto 64px auto", null, "auto"],
            "@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)": {
              display: "-ms-grid",
              msGridColumns: ["1fr", null, "1fr 64px 1fr"],
              msGridRows: ["auto 64px auto", null, "auto"],
            },
          })}
        >
          <Box
            css={css({
              gridColumn: 1,
              msGridColumn: 1,
              gridRow: 1,
              msGridRow: 1,
            })}
          >
            <Box
              position="relative"
              display="inline-block"
              mb={4}
              py={4}
              pr={4}
              bg="background"
            >
              <Link to="/contact" css={css({ textDecoration: "none" })}>
                <H2
                  children={"Contact your Branch"}
                  css={css({
                    wordBreak: "break-word",
                  })}
                />
              </Link>
              <Box
                position="absolute"
                top={0}
                left={0}
                height="100%"
                bg="background"
                css={css({
                  transform: "translateX(-100%)",
                  width: "32px",
                  "@media screen and (min-width: 52em)": {
                    width: "64px",
                  },
                  "@media screen and (min-width: 64em)": {
                    width: "128px",
                  },
                  "@media screen and (min-width: 1856px)": {
                    width: "calc(((100vw - 1601px) / 2))",
                  },
                })}
              >
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  width={[0, null, 32, 64]}
                  height="100%"
                  css={css({
                    backgroundImage: `url(${chevron})`,
                    backgroundSize: "cover",
                  })}
                />
              </Box>
            </Box>
            <Text
              children={`Each branch is independently owned and operated.`}
              fontSize={"10px"}
            />
          </Box>
          <Box
            css={css({
              gridColumn: [1, null, 3],
              msGridColumn: [1, null, 3],
              gridRow: [3, null, 1],
              msGridRow: [3, null, 1],
            })}
          >
            <Text
              children={`Contact your Falkbuilt Factory-direct Branch for unparalleled end-to-end service including pre-construction, project management, order entry, building permit submittals and installation.  We’re looking forward to bringing the power of Digital Component Construction to your project.`}
              fontFamily="condensed"
              fontSize={[5, 6]}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Locator
