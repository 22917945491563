import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { StaticQuery, graphql, Link } from "gatsby"
import css from "@styled-system/css"

import Box from "../box"
import Container from "../container"
import FalkLogo from "../falkLogo"
import Flex from "../flex"
import Text from "../text"
import { H6 } from "../headings"
import MobileMenu from "../mobileMenu"

const revealDuration = 0.5

const SubHeaderLink = ({ label, to, href }) => (
  <Text
    css={css({ whiteSpace: "nowrap", ":not(:nth-last-child(2))": { mr: 4 } })}
  >
    {to && (
      <Link
        children={label}
        to={to}
        activeStyle={{ opacity: 1, fontWeight: "bold" }}
        css={css({ opacity: 0.75, textDecoration: "none" })}
      />
    )}
    {href && (
      <a
        children={label}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        css={css({ opacity: 0.75, textDecoration: "none" })}
      />
    )}
  </Text>
)

const HeaderLink = ({ label, to }) => (
  <H6 letterSpacing={0}>
    <Link
      children={label}
      to={to}
      partiallyActive={true}
      activeStyle={{ color: "#000" }}
      css={css({ textDecoration: "none" })}
    />
  </H6>
)

const MobileLink = ({ label, to, onClick }) => (
  <Text
    mb={24}
    fontSize={20}
    css={css({ fontWeight: "bold", textTransform: "uppercase" })}
  >
    <Link
      to={to}
      onClick={onClick}
      activeClassName="active"
      partiallyActive={true}
      css={css({
        position: "relative",
        textDecoration: "none",
        "&.active > .tile": { opacity: 1 },
      })}
    >
      <Box
        className="tile"
        css={css({
          position: "absolute",
          top: 0,
          left: 0,
          width: 16,
          height: "100%",
          transform: "translateX(-32px)",
          bg: "primary",
          opacity: 0,
        })}
      />
      {label}
    </Link>
  </Text>
)

const Divider = () => (
  <Box
    width="1px"
    height={48}
    bg="primary"
    mx={4}
    css={css({ transform: "translateY(-2px) rotate(45deg)" })}
  />
)

const Header = ({ location }) => {
  const [showHeader, setShowHeader] = useState(true)
  const [mobileMenuActive, setMobileMenuActive] = useState(false)

  useEffect(() => {
    let priorScroll = 0
    const onScroll = () => {
      let newScroll =
        window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop
      if (newScroll < priorScroll || newScroll < 10) {
        setShowHeader(true)
      }
      if (newScroll > priorScroll && newScroll > 10) {
        setShowHeader(false)
      }
      priorScroll = newScroll
    }

    window.addEventListener("scroll", onScroll)

    return function cleanup() {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query {
          verticalMarkets: allDatoCmsVerticalMarket(
            sort: { fields: [meta___createdAt], order: ASC }
          ) {
            nodes {
              url
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <motion.div
            variants={{
              initial: {
                opacity: 0,
                y: -120,
              },
              enter: {
                y: 0,
                opacity: 1,
                transition: {
                  duration: revealDuration,
                  delay: revealDuration * 2,
                  when: "beforeChildren",
                },
              },
              exit: {
                y: -120,
                opacity: 0,
                transition: { duration: revealDuration / 2 },
              },
            }}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <Box
              as="header"
              position={"fixed"}
              top={0}
              left={0}
              right={0}
              bg="background"
              zIndex={9999}
              className={
                location.pathname.indexOf("/panoramas/") !== -1
                  ? "hidden"
                  : !showHeader
                    ? "hidden"
                    : ""
              }
              css={css({
                transform: "translate3d(0, 0,0)",
                transition: "transform 300ms linear",
                "&.hidden": {
                  transform: [
                    "translate3d(0, -96px, 0)",
                    "translate3d(0, -120px,0)",
                  ],
                },
              })}
            >
              <Container>
                <Flex justifyContent="space-between" alignItems="center">
                  <Link
                    to="/"
                    css={css({
                      opacity: mobileMenuActive ? 0 : 1,
                      transition: "opacity 200ms ease-in-out",
                      mt: 32,
                      mb: 24,
                      "&:focus": { outline: 0 },
                    })}
                    onClick={event => {
                      if (window.location.pathname === "/") {
                        window.refresh()
                      }
                    }}
                  >
                    <FalkLogo
                      css={css({
                        width: [(183 / 65) * 40, (183 / 65) * 64],
                        height: [40, 64],
                        color: "text",
                      })}
                    />
                  </Link>
                  <Flex
                    alignItems="center"
                    css={css({
                      "@media screen and (max-width: 1280px)": {
                        display: "none",
                      },
                    })}
                  >
                    {/* <HeaderLink
                      label={"Digital Components"}
                      to={"/components/"}
                    />
                    <Divider /> */}
                    <HeaderLink
                      label={"Gallery"}
                      to={`/vertical-markets/${data.verticalMarkets.nodes[0].url}/`}
                    />
                    <Divider />
                    <HeaderLink label={"Who We Are"} to={"/about/"} />
                    <Divider />
                    <HeaderLink label={"Echo Technology"} to={"/echo-technology/"} />
                    <Divider />
                    <HeaderLink label={"Blog"} to={"/blog/"} />
                    <Divider />
                    <HeaderLink label={"Contact"} to={"/contact/"} />
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="flex-end"
                    css={css({
                      "@media screen and (min-width: 1281px)": {
                        display: "none",
                      },
                    })}
                  >
                    <button
                      css={css({
                        m: 0,
                        p: 0,
                        border: 0,
                        bg: "transparent",
                        cursor: "pointer",
                        ":focus": {
                          outline: 0,
                        },
                      })}
                      onClick={() => {
                        setMobileMenuActive(true)
                      }}
                    >
                      {/* <svg width={40} height={40} fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 29.5L19.5 10l-.707-.707-19.5 19.5L0 29.5zm19.5 0L39 10l-.707-.707-19.5 19.5.707.707z"
                      fill="#FCD06B"
                    />
                  </svg> */}
                      <svg
                        width={32}
                        height={32}
                        fill="none"
                        viewBox="0 0 28 21"
                      >
                        <path
                          stroke="#FCD06B"
                          d="M0 .5h27.961M.039 10.11H28M.039 20.5H28"
                        />
                      </svg>
                    </button>
                  </Flex>
                </Flex>
              </Container>
            </Box>
            {(location.pathname.indexOf("/components/") !== -1 ||
              location.pathname.indexOf("/vertical-markets/") !== -1 ||
              location.pathname.indexOf("/industries/") !== -1 ||
              location.pathname.indexOf("/about/") !== -1 ||
              location.pathname.indexOf("/blog/") !== -1 ||
              location.pathname.indexOf("/videos/") !== -1 ||
              location.pathname.indexOf("/click-books/") !== -1) && (
                <Box
                  as={motion.div}
                  position={"fixed"}
                  top={[96, 120]}
                  left={0}
                  right={0}
                  bg="#F2F2F2"
                  zIndex={9999}
                  className={
                    location.pathname.indexOf("/panoramas/") !== -1
                      ? "hidden"
                      : !showHeader
                        ? "hidden"
                        : ""
                  }
                  css={css({
                    transform: "translate3d(0, 0,0)",
                    transition: "transform 300ms linear",
                    "&.hidden": {
                      transform: [
                        "translate3d(0, -96px, 0)",
                        "translate3d(0, -120px, 0)",
                      ],
                    },
                  })}
                >
                  <Box
                    css={css({
                      width: "100%",
                      maxWidth: "100%",
                      py: 3,
                      overflowX: ["scroll", "hidden"],
                      WebkitOverflowScrolling: "touch",
                    })}
                  >
                    <Container>
                      <Flex justifyContent="center" alignItems="center">
                        {location.pathname.indexOf("/components/") !== -1 && (
                          <Flex>
                            {/* <SubHeaderLink
                            label="Conventional Construction Alignment"
                            to="#"
                          />
                          <SubHeaderLink label="Technology" to="#" />
                          <SubHeaderLink label="Global Support" to="#" /> */}
                            <SubHeaderLink
                              label="Finishes + Materials"
                              to="/components/finishes-materials/"
                            />
                            {/* <SubHeaderLink label="Library" to="#" /> */}
                            <Box minWidth={[32, 0]} />
                          </Flex>
                        )}
                        {data.verticalMarkets.nodes.length > 0 &&
                          location.pathname.indexOf("/vertical-markets/") !==
                          -1 && (
                            <Flex>
                              {data.verticalMarkets.nodes.map((market, index) => {
                                return (
                                  <SubHeaderLink
                                    label={market.title}
                                    to={`/vertical-markets/${market.url}/`}
                                    key={`vertical-market-${index}`}
                                  />
                                )
                              })}
                              <Box minWidth={[32, 0]} />
                            </Flex>
                          )}
                        {location.pathname.indexOf("/about/") !== -1 && (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box />
                            <Flex>
                              <SubHeaderLink label="Who we are" to="/about/" />
                              <SubHeaderLink
                                label="Careers"
                                href="https://workforcenow.adp.com/jobs/apply/posting.html?client=falkerca&ccId=19000101_000001&type=MP&lang=en_CA"
                              />
                              <Box minWidth={[32, 0]} />
                            </Flex>
                          </Flex>
                        )}
                        {(
                          location.pathname.indexOf("/videos/") !== -1 ||
                          location.pathname.indexOf("/click-books/") !== -1) && (
                            <Flex>
                              <SubHeaderLink label="Blogs" to="/blog/" />
                              <SubHeaderLink label="Videos" to="/videos/" />
                              <Box minWidth={[32, 0]} />
                            </Flex>
                          )}
                      </Flex>
                    </Container>
                  </Box>
                </Box>
              )}
          </motion.div>
          <MobileMenu
            showDialog={mobileMenuActive}
            setShowDialog={setMobileMenuActive}
          >
            <Box mt={["4px", "16px"]}>
              {/* <MobileLink
                label="Components"
                to="/components/"
                onClick={() => {
                  setMobileMenuActive(false)
                }}
              /> */}
              <MobileLink
                label="Gallery"
                to={`/vertical-markets/${data.verticalMarkets.nodes[0].url}/`}
                onClick={() => {
                  setMobileMenuActive(false)
                }}
              />
              <MobileLink
                label="Who we are"
                to="/about/"
                onClick={() => {
                  setMobileMenuActive(false)
                }}
              />
              <MobileLink
                label="Echo Technology"
                to="/echo-technology/"
                onClick={() => {
                  setMobileMenuActive(false)
                }}
              />
              <MobileLink
                label="Blog"
                to="/blog/"
                onClick={() => {
                  setMobileMenuActive(false)
                }}
              />
              <MobileLink
                label="Contact"
                to="/contact/"
                onClick={() => {
                  setMobileMenuActive(false)
                }}
              />
            </Box>
          </MobileMenu>
        </>
      )}
    />
  )
}

export default Header